import React from 'react';
import PropTypes from 'prop-types';

import ShareButtons from './ShareButtons';

import styles from './CampaignForm.module.scss';

const CampaignFeedback = ({title, url}) => (
  <>
    <p className={styles.feedback}>Obrigado por assinar! Agora ajude a espalhar compartilhando nas suas redes sociais.</p>

    <div className={styles.shareButtons}>
      <ShareButtons title={title} url={url} />
    </div>
  </>
);

CampaignFeedback.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
};

CampaignFeedback.element = CampaignFeedback;

export default CampaignFeedback;
